import React from "react";

const StoreButtons = () => {
  return (
    <section className="w-full min-h-[250px]">
      <div className="pt-10 pb-10 w-full flex flex-col justify-center items-center md:flex-row">
        <div className="md:pr-5 min-h-15">
          <button
            type="button"
            className="flex items-center justify-center  mt-3"
            onClick={() =>
              window.open(
                "https://testflight.apple.com/join/Ioy8uXSW",
                "_blank"
              )
            }
          >
            <img src="/app-store-badge.svg" alt="App Store" className="h-20" />
          </button>
        </div>
        <div className="min-h-20">
          <button
            type="button"
            className="flex items-center justify-center  mt-3"
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.readwithjoey.joey_books",
                "_blank"
              )
            }
          >
            <img
              src="/google-play-badge.png"
              alt="Google Play"
              className="h-20 object-contain"
            />
          </button>
        </div>
      </div>
    </section>
  );
};

export default StoreButtons;
