import React from "react";

const Navbar = () => {
  return (
    <nav className="w-full bg-[#82bbe4]">
      <div className="relative z-10 mx-auto p-8">
        <div className="relative flex w-full items-center justify-end">
          <img
            src="/logo-white.svg"
            className="absolute left-0 top-2 h-20 cursor-pointer"
            alt="Joey Logo"
            onClick={() => (window.location.href = "/")}
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
