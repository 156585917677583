import React from "react";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/beta");
  }
  return (
    <section className="bg-[#82bbe4]  w-full">
      <div className="mx-auto max-w-3xl px-4 pb-24 pt-24 sm:px-8">
        <div className="text-center">
          <h2 className="mb-4 text-3xl font-bold sm:text-4xl">
            Unlock Endless Stories.
          </h2>
          <p className="mb-8 text-xl leading-relaxed sm:text-2xl sm:leading-relaxed">
            Dive into a world where every story fosters imagination and learning
            for just $9.99/month.
          </p>
          <button
            onClick={handleButtonClick}
            className="text-md rounded-xl bg-[#5a5988] px-4 py-4 font-bold text-white transition-all hover:shadow-lg md:px-6 md:text-lg"
          >
            Join now!

          </button>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
