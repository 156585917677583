import React from "react";
import ProductDemo from "../components/Home/ProductDemo";
import SignUp from "../components/Home/Signup";
import Title from "../components/Home/Title";
import UserStory from "../components/Home/UserStory";

const Home = () => {
  return (
    <main className="flex-1 bg-white text-black">
      <Title />
      <UserStory />
      <ProductDemo />
      <SignUp />
    </main>
  );
};

export default Home;
