import React from "react";

const Footer = () => {
  return (
    <div className="w-full">
      <div className="pt-10 max-h-[200px] w-full flex justify-center">
        <img
          src="/Friends.svg"
          alt="Friends"
          className="justify-center items-center"
        />
      </div>
      <div className="mx-auto flex w-full items-center justify-between px-4 py-6 sm:px-8">
        <span className="text-sm lg:text-lg">Copyright 2024</span>
        <div className="flex items-center space-x-8">
          <a className="text-sm lg:text-lg" href="/privacy-policy">
            Privacy Policy
          </a>
          <a className="text-sm lg:text-lg" href="/terms-of-service">
            Terms of Use
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
