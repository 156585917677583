import React from "react";
import { PopupButton } from "@typeform/embed-react";

const ProductDetails = () => {

  return (
    <section className="relative w-full">
      <div className="relative mx-auto max-w-7xl px-4 pb-20 pt-40 sm:px-8 justify-center items-center">
        <div className="flex items-center">
          <div className="w-full max-w-lg ">
            <h1 className="mb-4 text-5xl leading-tight">
              Join the beta now!
            </h1>
            <p className="mb-8 font-sans text-xl">
              Once you learn to read, you will be forever free.
            </p>
          </div>
        </div>
        {/* Hide on medium screens */}
        <div className="hidden md:block">
          <PopupButton
            id="xD5YMdak"
            className="mb-8 rounded-xl bg-[#5a5988] px-8 py-4 text-xl font-bold text-white transition-all hover:shadow-lg"
          >
            Submit Feedback
          </PopupButton>
        </div>
      </div>
    </section>
  );

};

export default ProductDetails;
