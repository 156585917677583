import React from "react";

const UserStory = () => {
  return (
    <section className="w-full">
      <div className="mx-auto max-w-7xl px-4 pb-12 pt-32 sm:px-8">
        <h2 className="mb-16 text-center text-3xl sm:mb-8 sm:text-4xl">
          Tap, Listen, and Learn.
        </h2>
        <div className="mb-16 flex flex-col justify-between space-y-8 md:flex-row md:space-x-16 md:space-y-0">
          <div className="flex flex-1 flex-col items-center justify-start text-center">
            <div className="flex h-80 flex-col items-center justify-end">
              <img
                src="/What_Ive_Read.svg"
                className="mb-8 w-64"
                alt="What I've Read"
              />
            </div>
            <h3 className="mb-4 text-2xl sm:w-56 sm:text-3xl">
              Engage you child's imagination
            </h3>
          </div>
          <div className="flex flex-1 flex-col items-center justify-start text-center">
            <div className="flex h-80 flex-col items-center justify-end">
              <img src="/Adventure.svg" className="mb-8 w-64" alt="Adventure" />
            </div>
            <h3 className="mb-4 text-2xl sm:w-56 sm:text-3xl">
              Increase your child's vocabulary
            </h3>
          </div>
          <div className="flex flex-1 flex-col items-center justify-start text-center">
            <div className="flex h-80 flex-col items-center justify-end">
              <img
                src="/Learn_to_Read.svg"
                className="mb-8 w-64"
                alt="Learning"
              />
            </div>
            <h3 className="mb-4 text-2xl sm:w-56 sm:text-3xl">
              Help your child's comprehension
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserStory;
