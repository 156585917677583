import Footer from "./components/Footer";
import Navbar from "./components/Home/Navbar";
import Home from "./pages/Home";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import Beta from "./pages/Beta";
import BetaNavbar from "./components/Beta/BetaNavbar";
import NotFound from "./pages/NotFound";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <div className="flex w-full flex-1 flex-col bg-white">
          <Navbar />
          <Home />
          <Footer />
        </div>,
    },
    
    {
      path: "/privacy-policy",
      element: <div className="flex w-full flex-1 flex-col bg-white">

      <PrivacyPolicy />
      <Footer />
    </div>,
  
    },
    {
      path: "/terms-of-service",
      element: <div className="flex w-full flex-1 flex-col bg-white">

      <TermsConditions />
      <Footer />
    </div>,
    },
    {
      path: "/beta",
      element: <div className="flex w-full h-screen flex-col bg-white">
        <BetaNavbar />
          <Beta />
          <Footer />
        </div>,
    },
    {
      path: "*",
      element: <div className="flex w-full flex-1 flex-col bg-white">
      <Navbar />
      <NotFound />
      <Footer />
    </div>,
    }
  ]);
  return (
    <div className="font-brevia">
      <RouterProvider router={router} />
      </div>
  );
}

export default App;
