import React from "react";
import ProductDetails from "../components/Beta/ProductDetails";
import BetaProductDemo from "../components/Beta/ProductDemo";
import Footer from "../components/Footer";

const Beta = () => {
  return (
    <main className="text-black md:min-h-screen bg-[#fef8ee]">
      <div className="flex flex-col md:flex-row w-full h-full">
        <div className="flex-1 flex" style={{ flexBasis: "40%" }}>
          <div
            className="flex h-full w-full items-center justify-center bg-[#c2e2fd] md:bg-transparent bg-cover"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/painted-background.svg)`,
            }}
          >
            <ProductDetails />
          </div>
        </div>
        <div
          className="flex-1 items-center justify-center h-full bg-[#fef8ee] md:flex"
          style={{ flexBasis: "60%" }}
        >
          <BetaProductDemo />
        </div>
      </div>
    </main>
  );
};

export default Beta;
