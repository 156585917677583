import React from "react";
import { useNavigate } from "react-router-dom";


const Title = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/beta");
  }
  return (
    <section className="relative w-full ">
      <div className="absolute inset-0 z-0 h-full w-full -translate-y-12 skew-y-3 bg-[#82bbe4]"></div>
      <div className="absolute h-20 w-full bg-[#82bbe4]" />
      <div className="relative mx-auto max-w-7xl px-4 pb-40 pt-32 sm:px-8 sm:pb-64 sm:pt-40">
        <div className="flex items-center">
          <div className="w-full max-w-lg lg:w-1/2">
            <h1 className="mb-4 text-5xl leading-tight">
              Unlock the Magic of Reading
            </h1>
            <p className="mb-8 font-sans text-xl">
              Once you learn to read, you will be forever free.
            </p>

            <button
              onClick={handleButtonClick}
              className="text-md rounded-xl bg-[#5a5988] px-4 py-4 font-bold text-white transition-all hover:shadow-lg md:px-6 md:text-lg"
            >
              Join now!

            </button>
          </div>
        </div>
      </div>
      <div className="pointer-events-none hidden lg:flex lg:flex-col lg:items-center lg:justify-center lg:absolute lg:top-0 lg:right-0 lg:h-full lg:w-1/2">
        <img
          src="/friends-joey-grass-circle.svg"
          className="absolute top-[200px]  h-80 z-0"
          alt="Joey and friends"
        />
        <img
          src="/Funny.png"
          className="absolute -top-10 lg:ml-[300px] h-[200px] z-0"
          alt="Funny"
        />
        <img
          src="/Classics.png"
          className="absolute top-[0px] lg:mr-[90px] h-[200px] z-0"
          alt="Classic"
        />
        <img
          src="/Fairy Tales.png"
          className="absolute top-[145px] lg:mr-[475px] h-[200px] z-0"
          alt="Fairy Tales"
        />
        <img
          src="/Nature.png"
          className="absolute top-[125px] lg:ml-[400px] h-[200px] z-0"
          alt="Nature"
        />
      </div>
    </section>
  );
};

export default Title;
