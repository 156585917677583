import React, { useState, useEffect } from "react";
import StoreButtons from "./StoreButtons";

const images = ["/phone-joey-2.png", "/phone-joey-1.png"];

const BetaProductDemo = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [opacity, setOpacity] = useState(1); // Start with full opacity

  useEffect(() => {
    // Start by fading out
    const fadeOut = setTimeout(() => setOpacity(0), 5000); // Start fade out 1 second before image change

    const changeImage = setTimeout(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
      setOpacity(1);
    }, 6000); // Change image every 6 seconds

    return () => {
      clearTimeout(changeImage);

      clearTimeout(fadeOut);
    };
  }, [currentImageIndex]);

  return (
    <div className="flex flex-col w-full  p-10  justify-center items-center bg-red">
      <img
        src={images[currentImageIndex]}
        alt="Slideshow"
        className={`flex justify-center items-center max-w-full max-h-[250px]  transition-opacity duration-1000 `}
        style={{
          opacity: opacity,
        }}
      />
      <StoreButtons className="flex justify-center items-center max-w-full" />
    </div>
  );
};

export default BetaProductDemo;
