import React from "react";

const NotFound = () => {
  return (
    <main className="flex-1 bg-white w-full text-black">
      <section className="  h-[600px] relative w-full flex bg-[#82bbe4] ">
        <div className="  w-full mx-auto  pb-40 pt-32 sm:px-8 sm:pb-64 sm:pt-40">
          <div className="flex items-center">
            <div className="w-full flex max-w-lg lg:w-1/2">
              <h1 className="mb-4 text-5xl w-ful">Page Not Found.</h1>
            </div>
          </div>
        </div>
        <div className="h-screen bg-[#82bbe4] pointer-events-none hidden lg:flex lg:flex-col lg:items-center lg:justify-center lg:absolute lg:top-0 lg:right-0 lg:h-full lg:w-1/2">
          <img
            src="/friends-joey-grass-circle.svg"
            className="absolute top-[200px]  h-80 z-0"
            alt="Joey and friends"
          />
          <img
            src="/Funny.png"
            className="absolute -top-10 lg:ml-[300px] h-[200px] z-0"
            alt="Funny"
          />
          <img
            src="/Classics.png"
            className="absolute top-[0px] lg:mr-[90px] h-[200px] z-0"
            alt="Classic"
          />
          <img
            src="/Fairy Tales.png"
            className="absolute top-[145px] lg:mr-[475px] h-[200px] z-0"
            alt="Fairy Tales"
          />
          <img
            src="/Nature.png"
            className="absolute top-[125px] lg:ml-[400px] h-[200px] z-0"
            alt="Nature"
          />
        </div>
      </section>
    </main>
  );
};

export default NotFound;
