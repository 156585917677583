import React from "react";

const BetaNavbar = () => {
  return (
    <nav className="w-full">
      <div className="absolute flex w-full items-center justify-end">
        <img
          src="/logo-white-beta.svg"
          className="absolute left-8 top-8 h-20 z-10 cursor-pointer"
          alt="Joey Logo"
          onClick={() => (window.location.href = "/")}
        />
      </div>
    </nav>
  );
};

export default BetaNavbar;
