import React, { useState } from "react";

const ProductDemo = () => {
  const [productScreenDemoImage, setProductScreenDemoImage] = useState(1);
  return (
    <section className="w-full bg-white">
      <div className="relative mx-auto max-w-7xl px-4 pb-12 pt-40 sm:px-8">
        <div className="mb-16 flex flex-col space-y-16 md:flex-row md:space-x-16 md:space-y-0">
          <div className="flex flex-1 flex-col">
            <h2 className="mb-8 text-3xl sm:text-4xl">
              Experience Joey yourself.
            </h2>
            <div className="space-y-4">
              <button
                onClick={() => setProductScreenDemoImage(1)}
                className={
                  productScreenDemoImage === 1
                    ? "bg-[#e2eded] flex w-full items-center rounded-full p-4 text-left transition-all"
                    : "bg-transparent hover:bg-white hover:bg-opacity-50 flex w-full items-center rounded-full p-4 text-left transition-all"
                }
              >
                <span className="mr-4 inline-flex h-10 w-14 items-center justify-center rounded-full bg-[#82bbe4] p-2">
                  <img src="/magnifying-glass.png" alt="explore icon" />
                </span>
                <span className="text-base font-bold sm:text-lg">
                  Explore personalised book recommendations that evolve with
                  your child's preferences and progress.
                </span>
              </button>
              <button
                className={
                  productScreenDemoImage === 0
                    ? "bg-[#e2eded] flex w-full items-center rounded-full p-4 text-left transition-all"
                    : "bg-transparent hover:bg-white hover:bg-opacity-50 flex w-full items-center rounded-full p-4 text-left transition-all"
                }
                onClick={() => setProductScreenDemoImage(0)}
              >
                <span className="mr-4 inline-flex h-10 w-10 items-center justify-center rounded-full bg-[#82bbe4]  p-2">
                  <img src="/storytelling.png" alt="story telling icon" />
                </span>
                <span className="text-base font-bold sm:text-lg">
                  Dive into stories with interactive elements.
                </span>
              </button>
            </div>
          </div>
          <div className="flex-1 ">
            <img
              alt="Joey app screen demo"
              src={
                productScreenDemoImage === 1
                  ? "/phone-joey-2.png"
                  : "/phone-joey-1.png"
              }
              className=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductDemo;
